function setCookie(cookieName, cookieValue, expiryDays) {
    let date = new Date();

    date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000));

    let expires = "expires=" + date.toUTCString();

    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/"
}

$('#cookie-banner-close').click(function(){
    setCookie('cookieConsent', true, 60);

    $('.cookie-banner-content').hide();
    $('.cookie-banner').hide();
});