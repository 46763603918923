$(document).ready(function() {

    let globalNavCollapse = $('.global-nav .navbar-collapse');
    let mainContent = $('main');

    globalNavCollapse.on("show.bs.collapse", function(){
        mainContent.addClass('blur');
    });

    globalNavCollapse.on("hide.bs.collapse", function(){
        mainContent.removeClass('blur');

    });

    $(window).resize(function() {
        if($(window).width() >= 992) {
            globalNavCollapse.collapse('hide');
        }
    });

});