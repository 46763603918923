// Based on script from https://coderwall.com/p/uf2pka/normalize-twitter-bootstrap-carousel-slide-heights

function carouselNormalization() {
    var items = $('.carousel .carousel-item'); // Fetch all slides
    let heights = []; // Create empty array to store height values
    let tallest; // Create variable to store the tallest slide

    if (items.length) {
        function normalizeHeights() {
            items.each(function() { // Add heights to array
                heights.push($(this).height());
            });

            tallest = Math.max.apply(null, heights); // Cache largest value

            items.each(function() {
                $(this).css('min-height', tallest + 'px');
            });
        }

        normalizeHeights();

        $(window).on('resize orientationchange', function () {
            tallest = 0;
            heights.length = 0; // Reset vars

            items.each(function() {
                $(this).css('min-height','0'); // Reset min-height
            });

            normalizeHeights(); // Run it again
        });
    }
}

carouselNormalization();